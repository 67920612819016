import pandaIcon from "../assets/yours-icon.png";

export type PandaConnectButtonProps = {
  onClick: () => void;
};

// NOTE: Using inline styling demo but prefer styled-components or CSS classes in real app
export const PandaConnectButton = (props: PandaConnectButtonProps) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "1rem",
        borderRadius: "0.5rem",
        border: "none",
        cursor: "pointer",
        fontSize: "1rem",
        fontWeight: 700,
        color: "#ffffff",
        backgroundColor: "#000000",
        zIndex: "10",
      }}
    >
      <img
        src={pandaIcon}
        alt="icon"
        style={{ marginRight: "1rem", width: "2.5rem", height: "2.5rem" }}
      />
      Connect Yours Wallet
    </button>
  );
};
