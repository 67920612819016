import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BackToHomeButton } from "../components/BackToHomeButton";
import { FoxplorerLogo } from "../components/FoxplorerLogo";
import { XLogoRight } from "../components/XLogoRight";
import {
  usePandaWallet,
  Ordinal
} from "panda-wallet-provider";
import { ThreeCircles } from 'react-loader-spinner';

const demojson = require('../assets/demo.json');



export const Demo = () => {


  //loading
  const [loading, setLoading] = useState<boolean>(false);

  //assign addresses once
  useEffect(() => {
    setLoading(true);
    handleGetFoxes();
  }, [])

  //navigate
  const navigate = useNavigate();

  //use yours waller
  // const wallet = usePandaWallet();

  //state management
  const [avatar, setAvatar] = useState<string | undefined>();
  const [ordinals, setOrdinals] = useState<Ordinal[] | undefined>();

  //fox container
  const container = document.getElementById('image-container');

  //useRefs
  const didMount1 = useRef(false);
  const didMount2 = useRef(false);

  //search filters
  const [background, setBackground] = useState<string | undefined>("all");
  const [name, setName] = useState<string | undefined>("all");
  const [body, setBody] = useState<string | undefined>("all");
  const [mouth, setMouth] = useState<string | undefined>("all");
  const [head, setHead] = useState<string | undefined>("all");
  const [eyes, setEyes] = useState<string | undefined>("all");
  const [item, setItem] = useState<string | undefined>("all");

  //search fields
  const [bgcheckboxes, setBgCheckboxes] = useState<string[] | undefined>();
  const [namecheckboxes, setNameCheckboxes] = useState<string[] | undefined>();
  const [bodycheckboxes, setBodyCheckboxes] = useState<string[] | undefined>();
  const [mouthcheckboxes, setMouthCheckboxes] = useState<string[] | undefined>();
  const [headcheckboxes, setHeadCheckboxes] = useState<string[] | undefined>();
  const [eyescheckboxes, setEyesCheckboxes] = useState<string[] | undefined>();
  const [itemcheckboxes, setItemCheckboxes] = useState<string[] | undefined>();

  //fox sorting useEffects
  useEffect(() => {
    handleChange();
  }, [background])

  useEffect(() => {
    handleChange();
  }, [name])

  useEffect(() => {
    handleChange();
  }, [body])

  useEffect(() => {
    handleChange();
  }, [mouth])

  useEffect(() => {
    handleChange();
  }, [head])

  useEffect(() => {
    handleChange();
  }, [eyes])

  useEffect(() => {
    handleChange();
  }, [item])


  //other useEffects
  useEffect(() => {
    if (!didMount1.current) {
      didMount1.current = true;
      return;
    }
    let outpoint: string = avatar || '';
    let ooo = outpoint.substring(outpoint.lastIndexOf("/") + 1)
    window.open("https://alpha.1satordinals.com/outpoint/" + ooo + "/inscription");
  }, [avatar])

  useEffect(() => {
    if (!didMount2.current) {
      didMount2.current = true;
      return;
    }
    setLoading(false)
    displayFoxes()
    handleAddCheckboxAttributes()
  }, [ordinals])

  const handleChange = async () => {
    //clear foxes
    document.getElementById('image-container')!.innerHTML = "";
    //get json data
    if (ordinals) {
      let rrr = JSON.stringify(ordinals);
      let jjj = JSON.parse(rrr);
      //sort foxes
      let temmp = "kjddj";
      var nietos = [];
      let nnn = jjj.length;
      //2.0 search
      for (let i = 0; i < nnn; i++) {
        //get fox json vars
        let mybg = jjj[i].data.map.subTypeData.traits[0].value;
        let myfox = jjj[i].data.map.subTypeData.traits[1].value;
        let mybody = jjj[i].data.map.subTypeData.traits[2].value;
        let mymouth = jjj[i].data.map.subTypeData.traits[3].value;
        let myhead = jjj[i].data.map.subTypeData.traits[4].value;
        let myeyes = jjj[i].data.map.subTypeData.traits[5].value;
        let myitem = jjj[i].data.map.subTypeData.traits[6].value;
        //push if we have a match
        if (((background === mybg) || (background === "all"))
          && ((name === myfox) || (name === "all"))
          && ((body === mybody) || (body === "all"))
          && ((mouth === mymouth) || (mouth === "all"))
          && ((head === myhead) || (head === "all"))
          && ((eyes === myeyes) || (eyes === "all"))
          && ((item === myitem) || (item === "all"))) {
          nietos.push(jjj[i]);
        }
      }
      temmp = JSON.stringify(nietos);
      displayFilteredFoxes(temmp);
    }
  };

  const displayFilteredFoxes = async (temmp: string) => {
    document.getElementById('image-container')!.innerHTML = "";
    let kkk = JSON.parse(temmp);
    let foxcount = 0;
    let foxlength = kkk.length;
    for (let i = 0; i < foxlength; i++) {
      if (kkk[0] !== undefined) if (kkk[0].data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
        const img = document.createElement('img');
        img.src = "https://ordfs.network/content/" + kkk[i].outpoint;
        img.className = "foxes";
        foxcount++;
        if (container === null) {
          return
        } else {
          container.appendChild(img);
        }
      }
    }
    if (foxcount === 0) {
      if (container === null) {
        return
      } else {
        container.innerHTML = "<p>You don't have any Foxes with these attributes:<br /><br />Background: " + background + "<br />Fox: " + name + "<br />Body: " + body + "<br />Mouth: " + mouth + "<br />Head Item: " + head + "<br />Eyes: " + eyes + "<br />Item: " + item + "</p>";
      }
    }
    //if foxes, add click events
    if (foxcount > 0) {
      var links = document.getElementsByClassName("foxes");
      var size = links.length;
      for (var i = 0; i < size; i++) {
        links[i].addEventListener("click", function (this: any) {
          setFoxUrl(this.getAttribute("src"));
        }, false);
      }
    }
  };

  //set avatar to build details link
  function setFoxUrl(foxurl: string | undefined) {
    setAvatar(foxurl);
  }

  //populate filter attributes
  const handleAddCheckboxAttributes = async () => {
    let rrr = JSON.stringify(ordinals);
    //options arrays
    const bgOptions: string[] = [];
    const nameOptions: string[] = [];
    const bodyOptions: string[] = [];
    const mouthOptions: string[] = [];
    const headOptions: string[] = [];
    const eyesOptions: string[] = [];
    const itemOptions: string[] = [];

    let kkk = JSON.parse(rrr)!;
    let foxlength = kkk.length;

    //background options
    for (let i = 0; i < foxlength; i++) {
      if (kkk[i].data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
        if (bgOptions.includes(kkk[i].data.map.subTypeData.traits[0].value)) {
          //do nothing
        } else {
          bgOptions.push(kkk[i].data.map.subTypeData.traits[0].value);
        }
      }
    }
    //fox name options
    for (let i = 0; i < foxlength; i++) {
      if (kkk[i].data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
        if (nameOptions.includes(kkk[i].data.map.subTypeData.traits[1].value)) {
          //do nothing
        } else {
          nameOptions.push(kkk[i].data.map.subTypeData.traits[1].value);
        }
      }
    }
    //body options
    for (let i = 0; i < foxlength; i++) {
      if (kkk[i].data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
        if (bodyOptions.includes(kkk[i].data.map.subTypeData.traits[2].value)) {
          //do nothing
        } else {
          bodyOptions.push(kkk[i].data.map.subTypeData.traits[2].value);
        }
      }
    }
    //mouthOptions options
    for (let i = 0; i < foxlength; i++) {
      if (kkk[i].data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
        if (mouthOptions.includes(kkk[i].data.map.subTypeData.traits[3].value)) {
          //do nothing
        } else {
          mouthOptions.push(kkk[i].data.map.subTypeData.traits[3].value);
        }
      }
    }
    //headOptions options
    for (let i = 0; i < foxlength; i++) {
      if (kkk[i].data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
        if (headOptions.includes(kkk[i].data.map.subTypeData.traits[4].value)) {
          //do nothing
        } else {
          headOptions.push(kkk[i].data.map.subTypeData.traits[4].value);
        }
      }
    }
    //eyesOptions options
    for (let i = 0; i < foxlength; i++) {
      if (kkk[i].data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
        if (eyesOptions.includes(kkk[i].data.map.subTypeData.traits[5].value)) {
          //do nothing
        } else {
          eyesOptions.push(kkk[i].data.map.subTypeData.traits[5].value);
        }
      }
    }
    //itemOptions options
    for (let i = 0; i < foxlength; i++) {
      if (kkk[i].data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
        if (itemOptions.includes(kkk[i].data.map.subTypeData.traits[6].value)) {
          //do nothing
        } else {
          itemOptions.push(kkk[i].data.map.subTypeData.traits[6].value);
        }
      }
    }
    setBgCheckboxes(bgOptions)
    setNameCheckboxes(nameOptions)
    setBodyCheckboxes(bodyOptions)
    setMouthCheckboxes(mouthOptions)
    setHeadCheckboxes(headOptions)
    setEyesCheckboxes(eyesOptions)
    setItemCheckboxes(itemOptions)
  };

  //initial fox display when ordinals state changes
  const clearFilters = async () => {
    var DropdownList = (document.getElementById("bgReset")) as HTMLSelectElement;
    DropdownList.selectedIndex = 0; // no error
    setBackground("all");

    var DropdownList = (document.getElementById("nameReset")) as HTMLSelectElement;
    DropdownList.selectedIndex = 0; // no error
    setName("all");

    var DropdownList = (document.getElementById("bodyReset")) as HTMLSelectElement;
    DropdownList.selectedIndex = 0; // no error
    setBody("all");

    var DropdownList = (document.getElementById("mouthReset")) as HTMLSelectElement;
    DropdownList.selectedIndex = 0; // no error
    setMouth("all");

    var DropdownList = (document.getElementById("headReset")) as HTMLSelectElement;
    DropdownList.selectedIndex = 0; // no error
    setHead("all");

    var DropdownList = (document.getElementById("eyesReset")) as HTMLSelectElement;
    DropdownList.selectedIndex = 0; // no error
    setEyes("all");

    var DropdownList = (document.getElementById("itemReset")) as HTMLSelectElement;
    DropdownList.selectedIndex = 0; // no error
    setItem("all");
  };
  //initial fox display when ordinals state changes
  const displayFoxes = async () => {
    document.getElementById('image-container')!.innerHTML = "";
    let rrr = JSON.stringify(ordinals);
    if (rrr) {
      let kkk = JSON.parse(rrr)!;
      let foxcount = 0;
      let foxlength = kkk.length;
      for (let i = 0; i < foxlength; i++) {
        if (kkk[0] !== undefined) if (kkk[0].data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
          const img = document.createElement('img');
          img.src = "https://ordfs.network/content/" + kkk[i].outpoint;
          img.className = "foxes";
          foxcount++;
          if (container === null) {
            return
          } else {
            container.appendChild(img);
          }
        }
      }
      //no foxes message
      if (foxcount === 0) {
        if (container === null) {
          return
        } else {
          container.innerHTML = "<p>You don't have any Foxes!<br /><br />Keep refreshing the page as ~1,500 foxes can take extra time to load.</p>";
        }
      }
      //if foxes, add click events
      if (foxcount > 0) {
        var links = document.getElementsByClassName("foxes");
        var size = links.length;
        for (var i = 0; i < size; i++) {
          links[i].addEventListener("click", function (this: any) {
            setFoxUrl(this.getAttribute("src"));
          }, false);
        }
      }
    }
  }
  //handle get foxes
  const handleGetFoxes = async () => {
    const fox = demojson;
    if (fox) setOrdinals(fox);
  }

  //handle connect for getting back to home
  const handleConnect = async () => {
    navigate("/");
  };

    //handle connect for getting back to home
    const goHome = async () => {
      navigate("/");
    };

  //return jsx
  return (
    <div className="App">
      <div className="Topbar">
        <FoxplorerLogo   onClick={goHome}/>
        <a target="blank" href="https://twitter.com/yours_foxplorer"><XLogoRight /></a>
      </div>

      <div id="Filters">
      <a href="" className="Back" onClick={goHome}><u>Back</u></a>
      {/* <BackToHomeButton onClick={goHome} /> */}
        <p className="Heading"><span className="Demo"><b>Fox Explorer Demo</b></span><br />Explore up to 1,500 <a className='White' target='blank' href='https://www.bubblemint.io/mint/pixel-foxes'><u>Pixel Foxes</u></a>
        </p>
       
        <h3>Filters</h3>
        
     
        <ul className="FilterList">
          <li>
        {bgcheckboxes &&
              <><label className="Label">Select Background</label>
                <select className="classic" id="bgReset" onChange={(e) => {
                  const value = e.target.value;
                  setBackground(value);
                }}>
                  <option key="all" value="all">All</option>
                  {bgcheckboxes.map(function (data) {
                    return (
                      <option key={data} value={data}>{data}</option>
                    )
                  })}
                </select><br />
              </>
            }
            </li><li>
            {namecheckboxes &&
              <><label className="Label">Select Name</label>
                <select id="nameReset" onChange={(e) => {
                  const value = e.target.value;
                  setName(value);
                }}>
                  <option key="all" value="all">All</option>
                  {namecheckboxes.map(function (data) {
                    return (
                      <option key={data} value={data}>{data}</option>
                    )
                  })}
                </select><br />
              </>
            }
             </li><li>
            {bodycheckboxes &&
              <><label className="Label">Select Body</label>
                <select id="bodyReset" onChange={(e) => {
                  const value = e.target.value;
                  setBody(value);
                }}>
                  <option key="all" value="all">All</option>
                  {bodycheckboxes.map(function (data) {
                    return (
                      <option key={data} value={data}>{data}</option>
                    )
                  })}
                </select><br />
              </>
            }
                  </li><li>
            {mouthcheckboxes &&
              <><label className="Label">Select Mouth</label>
                <select id="mouthReset" onChange={(e) => {
                  const value = e.target.value;
                  setMouth(value);
                }}>
                  <option key="all" value="all">All</option>
                  {mouthcheckboxes.map(function (data) {
                    return (
                      <option key={data} value={data}>{data}</option>
                    )
                  })}
                </select><br />
              </>
            }
                  </li><li>
            {headcheckboxes &&
              <><label className="Label">Select Head</label>
                <select id="headReset" onChange={(e) => {
                  const value = e.target.value;
                  setHead(value);
                }}>
                  <option key="all" value="all">All</option>
                  {headcheckboxes.map(function (data) {
                    return (
                      <option key={data} value={data}>{data}</option>
                    )
                  })}
                </select><br />
              </>
            }
                  </li><li>
            {eyescheckboxes &&
              <><label className="Label">Select Eyes</label>
                <select id="eyesReset" onChange={(e) => {
                  const value = e.target.value;
                  setEyes(value);
                }}>
                  <option key="all" value="all">All</option>
                  {eyescheckboxes.map(function (data) {
                    return (
                      <option key={data} value={data}>{data}</option>
                    )
                  })}
                </select><br />
              </>
            }
                  </li><li>
            {itemcheckboxes &&
              <><label className="Label">Select Item</label>
                <select id="itemReset" onChange={(e) => {
                  const value = e.target.value;
                  setItem(value);
                }}>
                  <option key="all" value="all">All</option>
                  {itemcheckboxes.map(function (data) {
                    return (
                      <option key={data} value={data}>{data}</option>
                    )
                  })}
                </select><br />
              </>
            }
           
           </li>
           </ul>
               
        <a className="Clear" onClick={clearFilters}><u>Clear Filters</u></a>
        </div>
        <div className="Foxplorer">
<p id="image-container">

</p>
<p>©2024 foxplorer.org</p>
</div>

      </div>

    
  );
};