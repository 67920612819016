import "./App.css";
import { Foxplorer } from "./page/Foxplorer";
import { Demo } from "./page/Demo";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

export const App = () => {
  
  return (
  
    <>
    {/* This is the alias of BrowserRouter i.e. Router */}
    <Router>
        <Routes>
            {/* This route is for home component 
  with exact path "/", in component props 
  we passes the imported component*/}
            <Route
                path="/"
                element={<Foxplorer />}
            />

            {/* This route is for about component 
  with exact path "/about", in component 
  props we passes the imported component*/}
            <Route
                path="/demo"
                element={<Demo />}
            />
        </Routes>
    </Router>
</>
  );
}